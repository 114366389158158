import * as React from "react";
import { Link } from "gatsby";
import DefaultLayout from "../layouts/DefaultLayout";

const NotFoundPage = () => (
  <DefaultLayout pageTitle="Page Not Found">
    <div className="container">
      <h1>404: Page not found.</h1>
      <p>
        You've hit the void. <Link to="/">Go back.</Link>
      </p>
    </div>
  </DefaultLayout>
);

export default NotFoundPage;
